<template>
  <div>
    <s-dialog :open="true" :to="uuid">
      <s-dialog-overlay />
      <s-dialog-panel class="w-[39.2rem]">
        <s-dialog-content>
          <s-dialog-content-body>
            <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">{{ $t(title) }}</p>
            <safe-html
              v-if="type === 'request'"
              tag="p"
              class="mt-4 text-lg leading-lg text-on-surface-elevation-4"
              :html="$t('studio.group.collection.list_more_btn_delete_request_pop_guide')"
            />
            <safe-html
              v-else
              tag="p"
              class="mt-4 text-lg leading-lg text-on-surface-elevation-4"
              :html="`${$t('studio.prj_prod_mngmt.popup_prod_del_guide2')}<br/>${$t(
                'studio.prj_prod_mngmt.popup_prod_del_guide3'
              )}`"
            />
            <div class="mt-8">
              <input-text
                name="typingDelete"
                :placeholder="DELETE_TEXT"
                :maxLength="DELETE_TEXT.length.toString()"
                :allowInputMaxLength="false"
                :clearable="false"
                :countable="false"
                inputClass="uppercase"
              />
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button variant="outline" @click="closeDialog">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button
            variant="red"
            :isDisabled="typingDelete.toUpperCase() !== DELETE_TEXT"
            @click="confirm"
          >
            {{ $t('studio.prj_prod_mngmt.popup_prod_del_cf_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="uuid" />
  </div>
</template>
<script setup lang="ts">
import { useFieldValue, useForm } from 'vee-validate';
import { computed } from 'vue';

import safeHtml from '@/components/common/safe-html.vue';
import InputText from '@/components/validation/input-text.vue';
import { generateUUID } from '@/utils/uuid.util';

const DELETE_TEXT = 'DELETE';

const props = defineProps<{
  type: 'normal' | 'request';
}>();

const title = computed(() => {
  if (props.type === 'request') {
    return 'studio.group.collection.list_more_btn_delete_request';
  }

  return 'studio.prj_prod_mngmt.popup_unreleased_prod_del_title';
});

const { handleSubmit } = useForm({
  initialValues: {
    typingDelete: ''
  }
});

const typingDelete = useFieldValue<string>('typingDelete');

const uuid: string = `stds-dialog-${generateUUID()}`;

const emit = defineEmits<{ close: [v: boolean] }>();

const closeDialog = () => {
  emit('close', false);
};

const confirm = handleSubmit(() => {
  if (typingDelete.value.toUpperCase() === DELETE_TEXT) {
    emit('close', true);
  }
});
</script>
