<template>
  <div class="max-w-[96rem] mx-auto">
    <st-title :title="$t('studio.lnb.common_2depth_collection')">
      <s-button class="min-w-[11.6rem]" variant="primary" size="md" @click="handleCollection">
        {{ $t('studio.group.collection.make_btn') }}
      </s-button>
    </st-title>
    <st-box class="mt-40">
      <div
        v-if="collectionList.length === 0 && !isLoading && isFirstTime"
        class="flex w-full flex-col items-center gap-20 py-20"
      >
        <div class="block h-188 w-272">
          <img
            src="@/assets/images/product/product-page-img.svg"
            alt=""
            class="w-full h-full object-cover"
          />
        </div>
        <h3
          class="break-keep text-center text-3xl font-bold leading-2xl text-on-surface-elevation-1"
        >
          <safe-html :html="$t('studio.group.collection.guide1')" />
        </h3>
      </div>

      <template v-else>
        <div class="flex items-center justify-between">
          <s-text as="h3" role="title6" class="font-bold">
            <span class="text-on-surface-elevation-2">{{
              hasSearched
                ? $t('studio.group.collection.list_search_result', { searchKeyWord: searchKeyWord })
                : $t('studio.group.collection.list_total')
            }}</span>
            <span class="text-3xl leading-2xl text-brand-primary ml-4">{{ total }}</span>
          </s-text>
          <div class="w-260">
            <input-text
              v-model="searchText"
              searchable
              size="lg"
              :placeholder="$t('studio.group.collection.list_search_place_holder')"
              @search="handleSearch"
              @clear="handleSearch"
            />
          </div>
        </div>
        <div v-if="!hasSearched" class="flex gap-12 items-center mt-12 mb-20">
          <radio-buttons
            v-model="collectionType"
            containerClass="flex gap-8"
            name="discountType"
            :options="options"
            @update:modelValue="onCollectionTypeChange"
          />
        </div>
        <st-table
          :tableClass="`${
            hasSearched ? 'mt-12 ' : ''
          }[&>tbody>tr:hover>td:nth-child(1)>span]:underline`"
          :showHoverEffect="true"
          :columns="columns"
          :data="collectionList"
          :paging="{ total: total, show: true }"
          @pageChange="onPageChange"
        >
          <template #empty>
            <st-td colspan="8" aCenter>
              <s-text
                as="span"
                role="text3"
                class="flex items-center justify-center h-120 text-on-surface-elevation-4"
              >
                {{ $t('studio.group.collection.list_search_result_no') }}
              </s-text>
            </st-td>
          </template>
          <template #col-0="{ row }">
            <st-td aLeft class="cursor-pointer" @click="handleOnRowClick(row)">
              <s-text as="span" role="text3" class="line-clamp-2">
                {{ row.productName }}
              </s-text>
            </st-td>
          </template>
          <template #col-1="{ row }">
            <st-td aLeft class="cursor-pointer" @click="handleOnRowClick(row)">
              <s-text
                v-if="row.planStatus"
                as="span"
                role="text3"
                :class="getTextRedCondition(row) ? 'text-[#EC3B47]' : ''"
              >
                {{ $t(row.label) }}
                <st-tooltip-info :content="$t(getDisplaySanctionText(row.restrictStatus))" />
              </s-text>
            </st-td>
          </template>
          <template #col-2="{ row }">
            <st-td aLeft class="cursor-pointer" @click="handleOnRowClick(row)">
              <template v-if="getDisplayYnCondition(row)">
                <s-text
                  v-if="
                    row.pageSuspendYn === Confirmation.YES ||
                      row.displayYn === Confirmation.NO ||
                      row.saleStatus === SALE_STATUS.STOP ||
                      row.saleStatus === SALE_STATUS.END
                  "
                  as="span"
                  role="text3"
                >
                  {{ DISPLAY_STATUS.OFF }}
                </s-text>
                <s-text v-else as="span" role="text3" class="text-brand-primary">
                  {{ DISPLAY_STATUS.ON }}
                </s-text>
              </template>

              <s-text v-else as="span" role="text3" class="text-placeholder">-</s-text>
            </st-td>
          </template>
          <template #col-4="{ row }">
            <st-td aLeft class="cursor-pointer" @click="handleOnRowClick(row)">
              <s-text as="span" role="text3">
                {{ row.discountRate ? `${row.discountRate}%` : '-' }}
              </s-text>
            </st-td>
          </template>
          <template #col-5="{ row }">
            <st-td class="cursor-pointer px-12" @click="handleOnRowClick(row)">
              <s-text as="span" role="text3">
                {{ formatDateTime(row.releasedAt, DATE_TIME_DOMESTIC) }}
              </s-text>
            </st-td>
          </template>
          <template #col-6="{ row }">
            <st-td onlyButton>
              <s-button
                v-if="row.planStatus !== PLAN_STATUS.NONE"
                size="sm"
                variant="outline"
                @click="handleCollectionDiscount(row)"
              >
                {{ $t('studio.group.collection.list_header_discount_btn') }}
              </s-button>
            </st-td>
          </template>
          <template #col-7="{ row }">
            <st-td onlyButton>
              <s-menu-popup
                v-if="!row.options.includes(COLLECTION_MORE_OPTIONS.HIDE)"
                :distance="0"
                :offset="[-8, 14]"
                placement="left-start"
                trigger="click"
                class="inline-block"
              >
                <template #target>
                  <s-icon
                    size="3xl"
                    icon="ic-v2-content-option-vertical-fill"
                    :class="
                      row.options.length > 0
                        ? 'text-on-surface-elevation-2'
                        : 'text-disabled-variant-1'
                    "
                  />
                </template>
                <template #menuItems>
                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.RESUME_SELL)"
                    class="min-w-[16rem]"
                    value="sale_resume"
                    @click="handleResume(row, 'sale')"
                  >
                    {{ $t('studio.prj_prod.this_prod.stt_release_sale_resumed') }}
                  </s-menu-popup-item>
                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.RESUME_DISPLAY)"
                    class="min-w-[16rem]"
                    value="reopening"
                    @click="handleResume(row, 'display')"
                  >
                    {{ $t('studio.group.collection.list_more_btn_reopening') }}
                  </s-menu-popup-item>
                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.RESUME_OPEN)"
                    class="min-w-[16rem]"
                    value="cancel_request_delete"
                    @click="handleResume(row, 'open')"
                  >
                    {{ $t('studio.prj_prod_mngmt.kebap_prod_pg_open_resume_btn') }}
                  </s-menu-popup-item>

                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.OFF_SELL)"
                    class="min-w-[16rem] text-tint-red-a400"
                    value="sale_discontinued"
                    @click="handleSuspend(row, 'sale')"
                  >
                    {{ $t('studio.group.collection.list_more_btn_sales_suspention') }}
                  </s-menu-popup-item>
                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.OFF_DISPLAY)"
                    class="min-w-[16rem] text-tint-red-a400"
                    value="exhibition_discontinued"
                    @click="handleSuspend(row, 'display')"
                  >
                    {{ $t('studio.group.collection.list_more_btn_display_suspention') }}
                  </s-menu-popup-item>
                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.OFF_OPEN)"
                    class="min-w-[16rem] text-tint-red-a400"
                    value="open_discontinued"
                    @click="handleSuspend(row, 'open')"
                  >
                    {{ $t('studio.group.collection.list_more_btn_opening_suspention') }}
                  </s-menu-popup-item>

                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.DELETE_REQUEST)"
                    class="min-w-[16rem] text-tint-red-a400"
                    value="request_delete"
                    @click="handleRequestDelete(row, 'request')"
                  >
                    {{ $t('studio.group.collection.list_more_btn_delete_request') }}
                  </s-menu-popup-item>
                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.CANCEL_DELETE_REQUEST)"
                    class="min-w-[16rem"
                    value="cancel_request_delete"
                    @click="handleCancelDeleteRequest(row)"
                  >
                    {{ $t('studio.group.collection.list_more_btn_cancel_delete_request') }}
                  </s-menu-popup-item>
                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.DELETE)"
                    class="min-w-[16rem] text-tint-red-a400"
                    value="cancel_request_delete"
                    @click="handleRequestDelete(row, 'normal')"
                  >
                    {{ $t('studio.group.collection.del_prod_from_list_btn') }}
                  </s-menu-popup-item>

                  <s-menu-popup-item
                    v-if="row.options.includes(COLLECTION_MORE_OPTIONS.GO_TO_STORE)"
                    class="min-w-[16rem]"
                    value="to_store"
                    @click="handleGoToStore(row)"
                  >
                    {{ $t('studio.group.collection.list_more_btn_go_to_store') }}
                  </s-menu-popup-item>
                </template>
              </s-menu-popup>
            </st-td>
          </template>
        </st-table>
      </template>
    </st-box>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { fetchCollectionListApi } from '@/apis/collection.api';
import { suspendProductPageApi } from '@/apis/product-page.api';
import {
  deleteRequestProductApi,
  modifyProductDisplayApi,
  modifyProductSaleStatusApi
} from '@/apis/project-product.api';
import DeleteCollectionDialog from '@/components/collection/delete-collection-dialog.vue';
import ResumeCollectionDialog from '@/components/collection/resume-collection-dialog.vue';
import SuspendCollectionDialog from '@/components/collection/suspend-collection-dialog.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import StTooltipInfo from '@/components/common/st-tooltip-info.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import inputText from '@/components/validation/input-text.vue';
import RadioButtons from '@/components/validation/radio-buttons.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import {
  COLLECTION_MORE_OPTIONS,
  COLLECTION_STATUS,
  SCHEDULE_STATUS
} from '@/constants/collection.const';
import { MODE, SORT_DIRECTION } from '@/constants/common.const';
import { DATE_TIME_DOMESTIC } from '@/constants/date-time.const';
import { STATUS_CODE } from '@/constants/error.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import { DISPLAY_STATUS } from '@/constants/product-page.const';
import { DELETE_REQUEST_STATUS, PLAN_STATUS, SALE_STATUS } from '@/constants/products.const';
import { COLLECTION_REGISTER_URL, COLLECTION_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import type { CollectionListItem, CollectionStatusType } from '@/types/collection/collection.type';
import type { CollectionSearchRequest } from '@/types/collection/collection-request.type';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { throwContentError } from '@/utils/api-error.util';
import { getConfigs, redirectTo, sortValueToDirection } from '@/utils/common.util';
import { formatDateTime } from '@/utils/date.util';
import { getCollectionStatusAndOptions, getDisplaySanctionText } from '@/utils/product.util';

interface CollectionListItemRender extends CollectionListItem {
  label: string;
  options: string[];
}

const app = useApp();

const { t, locale } = useI18n();

const appStore = useAppStore();
const userStore = useUserStore();

const { selectedGroupId } = storeToRefs(userStore);
const { isLoading } = storeToRefs(appStore);

const collectionList = ref<CollectionListItemRender[]>([]);
const searchText = ref<string>('');
const searchKeyWord = ref<string>('');
const direction = ref<string>(SORT_DIRECTION.DESC);
const currentPage = ref<number>(1);
const total = ref<number>(0);
const pageSize = 10;
const sortKey = ref<string>('CREATED_AT');
const collectionType = ref<string>(COLLECTION_STATUS.ALL);
const hasSearched = ref<boolean>(false);
const isFirstTime = ref<boolean>(true);

const options = ref<FormOption[]>([
  {
    label: t('studio.group.collection_list.filter_all'),
    value: COLLECTION_STATUS.ALL
  },
  {
    label: t('studio.group.collection_list.filter_prod_pg_only'),
    value: COLLECTION_STATUS.PAGE_RELATED
  },
  {
    label: t('studio.group.collection_list.filter_official_release'),
    value: COLLECTION_STATUS.RELEASED
  },
  {
    label: t('studio.group.collection_list.filter_suspended_or_deleted'),
    value: COLLECTION_STATUS.NOT_AVAILABLE
  }
]);

const path = computed(() => {
  return `/${selectedGroupId.value}/collections`;
});

const getDisplayYnCondition = (collection: CollectionListItem) => {
  if (collection.planStatus === PLAN_STATUS.READY) {
    return false;
  }

  if (collection.planStatus === PLAN_STATUS.PAGE_OPEN) {
    if (collection.pageOpenSchedule.scheduleStatus !== SCHEDULE_STATUS.END) {
      return true;
    }
    if (collection.pageOpenSchedule.scheduleStatus === SCHEDULE_STATUS.END) {
      return false;
    }
  }

  if (
    collection.planStatus !== PLAN_STATUS.NONE &&
    (collection.deleteRequestStatus === DELETE_REQUEST_STATUS.NONE ||
      collection.deleteRequestStatus === DELETE_REQUEST_STATUS.REJECT)
  ) {
    return true;
  }

  return false;
};

const handleOnRowClick = (item: CollectionListItem) => {
  redirectTo(`${path.value}/${MODE.EDIT}?productNo=${item.productNo}`);
};

const columns: Column[] = [
  {
    title: 'studio.group.collection.list_header_collection_name',
    dataIndex: 'productName',
    width: 'auto',
    align: 'left',
    sortKey: 'COLLECTION_PRODUCT_NAME',
    cellClick: handleOnRowClick,
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey)
  },
  {
    title: 'studio.group.collection.list_header_status',
    dataIndex: 'planStatus',
    width: '12rem',
    align: 'left'
  },
  {
    title: 'studio.group.collection.list_header_display',
    dataIndex: 'pageSuspendYn',
    width: '8.6rem',
    cellClick: handleOnRowClick,
    align: 'left'
  },
  {
    title: 'studio.group.collection.list_header_product_count',
    dataIndex: 'childProductCount',
    width: '9.6rem',
    align: 'left',
    sortKey: 'CHILD_PRODUCT_COUNT',
    cellClick: handleOnRowClick,
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey),
    titleTooltip: 'studio.tooltip.product_count'
  },
  {
    title: 'studio.group.collection.list_header_discount_rate',
    width: '8.6rem',
    align: 'left',
    dataIndex: 'discountRate',
    cellClick: handleOnRowClick,
    sortKey: 'DISCOUNT_RATE',
    titleTooltip: 'studio.group.collection.register_discount_rate',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey)
  },
  {
    title: 'studio.group.collection.list_header_release_date',
    width: '16rem',
    align: 'left',
    dataIndex: 'releasedAt',
    sortKey: 'RELEASED_AT',
    cellClick: handleOnRowClick,
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey)
  },
  { title: '', width: '7.2rem', align: 'left' },
  { title: '', width: '4rem', align: 'left' }
];

const getTextRedCondition = (collection: CollectionListItem) => {
  return (
    (collection.deleteRequestStatus !== DELETE_REQUEST_STATUS.NONE &&
      collection.deleteRequestStatus !== DELETE_REQUEST_STATUS.REJECT) ||
    collection.saleStatus === SALE_STATUS.END ||
    collection.saleStatus === SALE_STATUS.STOP ||
    collection.displayYn === Confirmation.NO
  );
};

const handleCollection = () => {
  redirectTo(COLLECTION_REGISTER_URL);
};

const handleCollectionDiscount = (row: CollectionListItem) => {
  redirectTo(`${COLLECTION_URL}/${row.productNo}/discount`);
};

const handleSuspend = async (collection: CollectionListItem, type: 'sale' | 'open' | 'display') => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  const dialog = await showDialog<'sale' | 'open' | 'display'>({
    component: shallowRef(SuspendCollectionDialog),
    props: {
      type
    },
    severity: 'info'
  });

  if (dialog === 'sale') {
    try {
      const result = await modifyProductSaleStatusApi(
        {
          productNo: collection.productNo,
          saleYn: Confirmation.NO
        },
        false
      );

      if (result?.success) {
        await fetchCollections({});
      }
    } catch (error) {}
  }

  if (dialog === 'open') {
    try {
      const result = await modifyProductDisplayApi(
        {
          productNo: collection.productNo,
          displayYn: Confirmation.NO
        },
        false
      );

      if (result?.success) {
        await fetchCollections({});
      }
    } catch (error) {}
  }

  if (dialog === 'display') {
    try {
      const result = await suspendProductPageApi(collection.pageId, Confirmation.YES);

      if (result?.suspended !== null || result?.suspended !== undefined) {
        await fetchCollections({});
      }
    } catch (error) {}
  }
};

const handleResume = async (collection: CollectionListItem, type: 'sale' | 'open' | 'display') => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  const dialog = await showDialog<'sale' | 'open' | 'display'>({
    component: shallowRef(ResumeCollectionDialog),
    props: {
      type
    },
    severity: 'info'
  });

  if (dialog === 'sale') {
    try {
      const result = await modifyProductSaleStatusApi(
        {
          productNo: collection.productNo,
          saleYn: Confirmation.YES
        },
        false
      );

      if (result?.success) {
        await fetchCollections({});
      }
    } catch (error) {}
  }
  if (dialog === 'open') {
    try {
      const result = await modifyProductDisplayApi(
        {
          productNo: collection.productNo,
          displayYn: Confirmation.YES
        },
        false
      );

      if (result?.success) {
        await fetchCollections({});
      }
    } catch (error) {}
  }

  if (dialog === 'display') {
    try {
      const result = await suspendProductPageApi(collection.pageId, Confirmation.NO);

      if (result?.suspended !== null || result?.suspended !== undefined) {
        await fetchCollections({});
      }
    } catch (error) {}
  }
};

const handleRequestDelete = async (collection: CollectionListItem, type: 'request' | 'normal') => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  if (
    collection.planStatus === PLAN_STATUS.RELEASE ||
    collection.planStatus === PLAN_STATUS.PRE_PURCHASE
  ) {
    if (collection.saleStatus === SALE_STATUS.START) {
      await showAlert({
        content: t('studio.prj_prod_mngmt.on_sale_cannot_del_msg'),
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        confirmClasses: '!w-full !max-w-full'
      });
      return;
    }
  }

  if (
    (collection.planStatus === PLAN_STATUS.PAGE_OPEN &&
      collection.displayYn === Confirmation.YES) ||
    (collection.planStatus !== PLAN_STATUS.PAGE_OPEN &&
      collection.planStatus !== PLAN_STATUS.READY &&
      collection.pageSuspendYn === Confirmation.NO)
  ) {
    if (
      collection.saleStatus === SALE_STATUS.START ||
      collection.saleStatus === SALE_STATUS.READY
    ) {
      await showAlert({
        content: t('studio.prj_prod_mngmt.page_open_cannot_del_msg'),
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        confirmClasses: '!w-full !max-w-full'
      });

      return;
    }
  }

  const result = await showDialog({
    component: shallowRef(DeleteCollectionDialog),
    props: {
      type
    }
  });

  if (result) {
    try {
      const deleteResult = await deleteRequestProductApi({
        productNo: collection.productNo,
        deleteRequestYn: Confirmation.YES
      });

      if (deleteResult?.success) {
        if (type === 'request') {
          const dialogResult = await showAlert({
            content: t('studio.prj_prod_mngmt.popup_prod_del_req_completed_msg'),
            confirmLabel: t('studio.common.popup_case_cf_btn'),
            confirmClasses: '!w-full !max-w-full'
          });

          if (dialogResult) {
            await fetchCollections({});
          }
        } else {
          await fetchCollections({});
        }
      }
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      if (error.statusCode === STATUS_CODE.PRODUCT_CONTAINS_SUB_PRODUCT) {
        await showAlert({
          content: t('studio.prj_prod_mngmt.child_prod_exist_cannot_del'),
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
        return;
      }
      if (error.statusCode === STATUS_CODE.PRODUCT_STATUS_NOT_MODIFIABLE) {
        await showAlert({
          content: `${t('studio.prj_prod_mngmt.del_req_x_on_sale_guide1')}<br/>${t(
            'studio.prj_prod_mngmt.del_req_x_on_sale_guide2'
          )}`,
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full'
        });
      }
    }
  }
};

const handleCancelDeleteRequest = async (collection: CollectionListItem) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  try {
    const result = await deleteRequestProductApi({
      productNo: collection.productNo,
      deleteRequestYn: Confirmation.NO
    });

    if (result?.success) {
      await throwContentError(t('studio.prj_prod_mngmt.kebap_del_req_cancelled_msg'));
      await fetchCollections({});
    }
  } catch (err) {}
};

const onPageChange = async (page: number) => {
  currentPage.value = page;
  await fetchCollections({});
};

const fetchCollections = async (params: { sort?: string }) => {
  const { sort } = params;

  const collectionSearchRequest: CollectionSearchRequest = {
    page: currentPage.value,
    size: pageSize,
    q: searchText.value,
    sort: sort ?? sortKey.value,
    direction: direction.value
  };

  if (collectionType.value !== COLLECTION_STATUS.ALL) {
    collectionSearchRequest.status = collectionType.value as CollectionStatusType;
  }

  const result = await fetchCollectionListApi(selectedGroupId.value, collectionSearchRequest);

  if (result) {
    collectionList.value = result.contents.map((c: CollectionListItem) => {
      return {
        ...c,
        label: getCollectionStatusAndOptions(
          c.planStatus,
          c.saleStatus,
          // c.restrictStatus,
          c.displayYn,
          c.deleteRequestStatus,
          c.pageOpenSchedule?.scheduleStatus,
          c?.pageSuspendYn
        ).label,
        options: getCollectionStatusAndOptions(
          c.planStatus,
          c.saleStatus,
          // c.restrictStatus,
          c.displayYn,
          c.deleteRequestStatus,
          c.pageOpenSchedule?.scheduleStatus,
          c?.pageSuspendYn
        ).options
      };
    });

    total.value = result.totalElements;
  }
};

const handleSort = (sortValue: number, sortKeyValue: string) => {
  const dir = sortValueToDirection(sortValue);
  sortKey.value = sortKeyValue;

  if (!dir) {
    direction.value = SORT_DIRECTION.DESC;
    sortKey.value = 'CREATED_AT';
    fetchCollections({});
    return;
  }

  direction.value = dir;
  fetchCollections({
    sort: sortKey.value
  });
};

const handleSearch = async () => {
  isFirstTime.value = false;
  if (!searchText.value || searchText.value === '') {
    hasSearched.value = false;
  } else {
    hasSearched.value = true;
  }
  currentPage.value = 1;
  searchKeyWord.value = searchText.value;
  await fetchCollections({});
};

const handleGoToStore = (row: CollectionListItem) => {
  const { STORE_URL } = getConfigs();
  const storeUrl = `${STORE_URL}/${locale.value ?? DEFAULT_LOCALE}/games/${
    row.productNo
  }`;

  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: storeUrl
  }).click();
};

const onCollectionTypeChange = async () => {
  currentPage.value = 1;
  isFirstTime.value = false;
  fetchCollections({});
};

const init = async () => {
  await fetchCollections({});
};

init();
</script>
