<template>
  <div>
    <s-dialog :open="true" :to="uuid">
      <s-dialog-overlay />
      <s-dialog-panel class="w-[39.2rem]">
        <s-dialog-content>
          <s-dialog-content-body>
            <s-text as="h3" role="title7" class="font-bold leading-xl text-on-surface-elevation-1">
              {{ $t(title) }}
            </s-text>
            <s-text as="p" role="text3" class="mt-4 text-lg leading-lg text-on-surface-elevation-3 tracking-[-0.45px]">
              <safe-html :html="$t(content)" as="span" />
            </s-text>
            <div class="text mt-8">
              <input-text
                name="inputValue"
                :placeholder="RESUME_TEXT"
                inputClass="uppercase"
                :maxLength="RESUME_TEXT.length.toString()"
                :allowInputMaxLength="false"
                :clearable="false"
                :countable="false"
              />
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button variant="outline" @click="closeDialog">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button
            variant="primary"
            :isDisabled="inputValue.toUpperCase() !== RESUME_TEXT"
            @click="confirm"
          >
            {{ $t('studio.prj_prod_mngmt.popup_restart_cf_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="uuid" />
  </div>
</template>
<script setup lang="ts">
import { useFieldValue, useForm } from 'vee-validate';
import { computed } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import InputText from '@/components/validation/input-text.vue';
import { closeDialog } from '@/composables/useDialog';
import { generateUUID } from '@/utils/uuid.util';

const props = defineProps<{
  type: 'sale' | 'open' | 'display';
}>();

const title = computed(() => {
  if (props.type === 'sale') {
    return 'studio.prj_prod_mngmt.popup_restart_sale_title';
  }
  if (props.type === 'open') {
    return 'studio.prj_prod.this_prod.stt_release_display_resumed';
  }
  return 'studio.prj_prod_mngmt.popup_prod_pg_reopen_title';
});

const content = computed(() => {
  if (props.type === 'sale') {
    return 'studio.prj_prod_mngmt.popup_restart_sale_guide';
  }
  if (props.type === 'open') {
    return 'studio.prj_prod.this_prod.stt_release_display_resumed_guide';
  }
  return 'studio.prj_prod_mngmt.popup_prod_pg_reopen_guide';
});

const RESUME_TEXT = props.type === 'sale' ? 'START' : 'ON';

const { handleSubmit } = useForm({
  initialValues: {
    inputValue: ''
  }
});

const inputValue = useFieldValue<string>('inputValue');

const uuid: string = `stds-dialog-${generateUUID()}`;

const emit = defineEmits<{ close: [t: 'sale' | 'open' | 'display'] }>();

const confirm = handleSubmit(() => {
  emit('close', props.type);
});
</script>
